<div class="modal-header p-3 m-0 d-flex justify-content-between align-items-center">
    <ng-container *ngIf="customHeader else defaultHeader">
      <ng-container [ngTemplateOutlet]="customHeader.templateRef"></ng-container>
    </ng-container>
    <ng-template #defaultHeader>
      <h4 class="modal-title">{{title}}</h4>
      <button type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal.emit()">
        <svg-icon src="assets/images/icons/common-close.svg"></svg-icon>
      </button>
    </ng-template>
  </div>
  
  <div class="modal-body p-3 m-0 body-min-h">
    <ng-container *ngIf="customBody else defaultBody">
      <ng-container [ngTemplateOutlet]="customBody.templateRef"></ng-container>
    </ng-container>
    <ng-template #defaultBody>
      <span>{{body}}</span>
    </ng-template>
  </div>
  
  <div class="modal-footer p-3 m-0">
    <ng-container *ngIf="customFooter else defaultFooter">
      <ng-container [ngTemplateOutlet]="customFooter.templateRef"></ng-container>
    </ng-container>
    <ng-template #defaultFooter>
      <button class="btn btn-outline py-2 m-0 mr-2 common-btn"
        [style.--c]="themeHeaderTableColor$ | async"
        (click)="closeModal.emit()">
        {{ cancelText ?? ('MEMOS.CANCEL'|translate) }}
      </button>
      <button class="btn btn-confirm py-2 m-0 common-btn"
        appSetTheme
        type="button"
        [ladda]="loading"
        (click)="confirmModal.emit()">
        {{ confirmText ?? ("MEMOS.CONFIRM"| translate)}}
      </button>
    </ng-template>
  </div>
  