import {
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { ThemeService } from '../../service/theme.service';
import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  pluck,
  takeUntil,
} from 'rxjs/operators';

@Directive({
  selector: '[appCustomModalHeader2]',
})
export class CustomModalHeader2Directive {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appCustomModalBody2]',
})
export class CustomModalBody2Directive {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appCustomModalFooter2]',
})
export class CustomModalFooter2Directive {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss'],
})
export class CommonModalComponent implements OnDestroy {
  @Input() title: string;
  @Input() body: string;
  @Input() confirmText: string;
  @Input() cancelText: string;
  @Input() loading = false;

  @Output() closeModal = new EventEmitter();
  @Output() confirmModal = new EventEmitter();

  @ContentChild(CustomModalBody2Directive)
  customBody: CustomModalBody2Directive;

  @ContentChild(CustomModalFooter2Directive)
  customFooter: CustomModalFooter2Directive;

  @ContentChild(CustomModalHeader2Directive)
  customHeader: CustomModalHeader2Directive;

  destroy$ = new Subject();
  themeHeaderTableColor$ = this.themeService.data.pipe(
    takeUntil(this.destroy$),
    pluck('header_table_color'),
    distinctUntilChanged(),
  );

  constructor(private themeService: ThemeService) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
